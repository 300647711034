import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DashboardUserTable from '../../components/table/dashboard-user-table';
import BannedTable from '../../components/table/banned-table';
import ExpirationTable from '../../components/table/expiration-table';
import InactiveTable from '../../components/table/inactive-table';
import ChurnedTable from '../../components/table/churned-table';
import { ApplicationState } from '../../store';
import { StudentInfo } from '../../store/approval/types';
import moment from 'moment';
import PulseLoader from 'react-spinners/PulseLoader';
import './index.css';
import logoGrey from '../../images/logo/logo-text-nock-grey.png';
import logoGrey2x from '../../images/logo/logo-text-nock-grey@2x.png';
import logoGrey3x from '../../images/logo/logo-text-nock-grey@3x.png';

interface DashboardProps { roomId: string | null; }



const Dashboard: React.FC<DashboardProps> = ({ roomId }) => {

    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList?.data || []);
    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const [inactiveStudents, setInactiveStudents] = useState<{ studentId: string; inactive: number }[]>([]);
    const [loadingInactive, setLoadingInactive] = useState<boolean>(false);
    const [churnedStudents, setChurnedStudents] = useState<StudentInfo[]>([]);
    const [loadingChurned, setLoadingChurned] = useState<boolean>(false);
    const [showAll, setShowAll] = useState(false);

    const { token } = loginState.data;
    const filteredList = approvedList.filter(user => user.room_id === roomId);
    const hasBannedUsers = filteredList.some(user => user.state !== 0);
    const approvedCount = filteredList.length;
    const bannedCount = filteredList.filter(user => user.state !== 0).length;
    const inactiveCount = inactiveStudents.filter(student => ![1].includes(student.inactive)).length;



    const isExpiring = (user: StudentInfo) => [user.expire_time, user.expire_date].some(date => moment(date).isBefore(moment().add(2, 'weeks')));



    const hasExpiringUsers = filteredList.some(isExpiring);
    const expiringCount = filteredList.filter(isExpiring).length;



    useEffect(() => {
        const fetchInactiveStudents = async () => {
            setLoadingInactive(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/school/inactive?roomId=${roomId}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                });
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const { data } = await response.json();
                setInactiveStudents(data);
            } catch (error) {
                console.error('Error updating inactive students:', error);
            } finally {
                setLoadingInactive(false);
            }
        };
        if (!loadingInactive && roomId) fetchInactiveStudents();
    }, [approvedCount, roomId]);

    useEffect(() => {
        const fetchChurnedStudents = async () => {
            setLoadingChurned(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/school/users/ids/churn?roomId=${roomId}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                });
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const { data } = await response.json();
                setChurnedStudents(data);
            } catch (error) {
                console.error('Error fetching churned students:', error);
            } finally {
                setLoadingChurned(false);
            }
        };
        if (!loadingChurned && roomId) fetchChurnedStudents();
    }, [approvedCount, roomId]);



    return (
        <div className='content'>
            <div className='w-100 mb-5'>
                <h2 className='dashboard-header'>
                    Current Students
                    <div className="dashboard-count">({approvedCount})</div>
                </h2>
                <DashboardUserTable roomId={roomId} />
            </div>

            {hasExpiringUsers && (
                <div className='w-100 mb-5'>
                    <h2 className='dashboard-header'>
                        Expiration Pending Students
                        <div className="dashboard-count">({expiringCount})</div>
                    </h2>
                    <ExpirationTable roomId={roomId} />
                </div>
            )}

            {loadingInactive ? (
                <>
                    <h2 className="dashboard-header">Inactive Students</h2>
                    <div className="loader-container"><PulseLoader color="#0b7aff" /></div>
                </>
            ) : (
                inactiveStudents.length > 0 && (
                    <div className="w-100 mb-5">
                        <h2 className="dashboard-header">
                            Inactive Students
                            <div className="dashboard-count">
                                ({inactiveCount})
                            </div>
                            <button className="inactive-button" onClick={() => setShowAll(prev => !prev)} style={{ marginLeft: '10px' }}>
                                {showAll ? 'Hide' : 'Show All'}
                            </button>
                        </h2>
                        <InactiveTable inactiveStudents={inactiveStudents} showAll={showAll} />
                    </div>
                )
            )}

            {hasBannedUsers && (
                <div className='w-100 mb-5'>
                    <h2 className='dashboard-header'>
                        Banned Students
                        <div className="dashboard-count">({bannedCount})</div>
                    </h2>
                    <BannedTable />
                </div>
            )}

            {loadingChurned ? (
                <div className="w-100 mb-5">
                    <h2 className="dashboard-header">Churned Students</h2>
                    <div className="loader-container"><PulseLoader color="#0b7aff" /></div>
                </div>
            ) : (
                churnedStudents.length > 0 && (
                    <div className="w-100 mb-5">
                        <h2 className="dashboard-header">
                            Churned Students
                            <div className="dashboard-count">
                                ({churnedStudents.length})
                            </div>
                        </h2>
                        <ChurnedTable churnedStudents={churnedStudents} />
                    </div>
                )
            )}

            <div className='dashboard-logo-image-container'>
                <img src={logoGrey} srcSet={`${logoGrey2x} 2x, ${logoGrey3x} 3x`} alt='' />
            </div>
        </div>
    );
};

export default Dashboard;
