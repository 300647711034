import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest, deleteStudentRequest } from '../../store/approval/actions';
import { StudentInfo } from '../../store/approval/types';
import StudentFilter from '../../containers/filter/student-filter';
import UserPendingList from './user-table-pending';
import userImage from '../../images/user/user-image.png';
import './user-table.css';

interface SchoolRoom { id: number; name: string; }



const UserTable = () => {

    const dispatch = useDispatch();
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList?.data || []);
    const loginState = useSelector((state: ApplicationState) => state.autherize);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [schoolRooms, setSchoolRooms] = useState<SchoolRoom[]>([]);

    const [expandedNames, setExpandedNames] = useState<Set<string>>(new Set());
    const [expandedNicknames, setExpandedNicknames] = useState<Set<string>>(new Set());

    const [nameSortDir, setNameSortDir] = useState<'asc' | 'desc' | null>('asc');

    const { token } = loginState.data;

    const usersPerPage = 10;

    const filteredUsers = approvedList.filter(user => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const fieldsToSearch = [
            user.id?.toString(), user.fullname, user.login, user.address, user.parentPhone
        ];
        return fieldsToSearch.some(field => field?.toLowerCase().includes(lowerCaseSearchTerm));
    });

    const totalPages = Math.ceil(approvedList.length / usersPerPage);



    const handleRejectClick = (data: StudentInfo) => {
        const isConfirmed = window.confirm("Are you sure you want to remove this student?");
        if (isConfirmed) dispatch(deleteStudentRequest(data));
    };

    const renderPaginationButton = (pageNumber: number) => (
        <button
            key={pageNumber} onClick={() => setCurrentPage(pageNumber)}
            className={`user-pagination-button ${pageNumber === currentPage ? 'active' : ''}`}
        >
            {pageNumber}
        </button>
    );

    const toggleName = (id: string) => {
        setExpandedNames(prev => {
            const newSet = new Set(prev);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);
            return newSet;
        });
    };

    const toggleNickname = (id: string) => {
        setExpandedNicknames(prev => {
            const newSet = new Set(prev);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);
            return newSet;
        });
    };

    const fetchCourses = async () => {
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/school-room`, token);
            setSchoolRooms(response.schoolRooms);
        } catch (error) {
            console.error('Error fetching school rooms:', error);
        }
    };

    const handleCourses = async (userId: string, roomId: string) => {
        try {
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/student/room/update`, token, { userId, roomId });
            dispatch(fetchApprovedListRequest());
        } catch (err) {
            console.error('Error updating school room ID:', err);
        }
    };

    const handleSortName = () => {
        setNameSortDir(nameSortDir === 'asc' ? 'desc' : 'asc');
        setCurrentPage(1);
    };

    const sortByName = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const nameA = a.fullname?.toLowerCase() || '';
        const nameB = b.fullname?.toLowerCase() || '';
        return direction === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    };



    useEffect(() => {
        dispatch(fetchApprovedListRequest());
        fetchCourses();
    }, []);

    useEffect(() => { setCurrentPage(1); }, [searchTerm]);



    const currentUsers = filteredUsers
        .slice()
        .sort((a, b) => {
            if (nameSortDir) return sortByName(a, b, nameSortDir);
            return 0;
        })
        .slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage);

    return (
        <div>
            <div className="filter-container">
                <div className="user-search-container">
                    <input
                        type="text" placeholder="Search by ID, Name, or Course" value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} className="search-input"
                    />
                </div>
                <StudentFilter />
                {filteredUsers.length > usersPerPage && (
                    <div className="user-pagination">
                        <button
                            onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}
                            className="user-pagination-button"
                        >
                            Previous
                        </button>
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map(renderPaginationButton)}
                        <button
                            onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}
                            className="user-pagination-button"
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>

            <table className="table-container">
                <thead>
                    <tr>
                        <th className="user-table-center-nowrap">ID</th>
                        <th className="user-table-name" onClick={() => { handleSortName() }}>
                            Name {nameSortDir !== null ? (nameSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                        <th className="user-table-center-nowrap">Nickname</th>
                        <th className="user-table-center-nowrap">Course</th>
                        <th className="user-table-center-nowrap">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentUsers.length ? (
                        currentUsers.map((user) => {
                            const nickname = user.login && user.login !== user.fullname ? user.login : "-";
                            const isNameExpanded = expandedNames.has(user.id);
                            const isNicknameExpanded = expandedNicknames.has(user.id);
                            const truncatedName = user.fullname.length > 20 ? `${user.fullname.substring(0, 20)}...` : user.fullname;
                            const truncatedNickname = nickname.length > 20 ? `${nickname.substring(0, 20)}...` : nickname;

                            return (
                                <tr key={user.id}>
                                    <td className="user-table-center-nowrap">
                                        <img className="user-table-profile-image" src={user.lowPhotoUrl || userImage} alt="" />
                                        {user.id}
                                    </td>
                                    <td className="user-table-name">
                                        <span onClick={() => toggleName(user.id)} style={{ cursor: 'pointer' }}>
                                            {isNameExpanded ? user.fullname : truncatedName}
                                        </span>
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        <span onClick={() => toggleNickname(user.id)} style={{ cursor: 'pointer' }}>
                                            {isNicknameExpanded ? nickname : truncatedNickname}
                                        </span>
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        <select value={user.room_id} onChange={(e) => handleCourses(user.id, e.target.value)}>
                                            {schoolRooms.map((room, index) => (
                                                <option key={room.id || index} value={room.id}>
                                                    {room.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        <button className="remove-button" onClick={() => handleRejectClick(user)}>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr><td colSpan={7} className="no-playlists">No users available</td></tr>
                    )}
                </tbody>
            </table>

            <UserPendingList />
        </div>
    );
};

export default UserTable;
