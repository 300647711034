import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { mapGrade } from '../../utils/mapGrade';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest } from '../../store/approval/actions';
import userImage from '../../images/user/user-image.png';
import pencilIcon from '../../images/icons/pencil.svg';
import moment from 'moment';
import { StudentInfo } from '../../store/approval/types';

interface ChurnedTableProps { churnedStudents: StudentInfo[]; }
interface Delivery { id: string; user_id: string; stage: string; status: string; }
interface SchoolRoom { id: number; name: string; }
type DeliveryStatus = 'none' | 'physical' | 'ebook' | 'ignore';



const ChurnedTable: React.FC<ChurnedTableProps> = ({ churnedStudents }) => {

    const dispatch = useDispatch();
    const loginState = useSelector((state: ApplicationState) => state.autherize);

    const [currentPage, setCurrentPage] = useState(1);
    const [deliveries, setDeliveries] = useState<Delivery[]>([]);
    const [schoolRooms, setSchoolRooms] = useState<SchoolRoom[]>([]);

    const [expandedNames, setExpandedNames] = useState<Set<string>>(new Set());
    const [expandedNicknames, setExpandedNicknames] = useState<Set<string>>(new Set());
    const [expandedAddresses, setExpandedAddresses] = useState<Set<string>>(new Set());

    const [editingExamResult, setEditingExamResult] = useState<string | null>(null);
    const [examResultInputValues, setExamResultInputValues] = useState<{ [key: string]: string }>({});

    const [nameSortDir, setNameSortDir] = useState<'asc' | 'desc' | null>('asc');
    const [premiumPlusExpSortDir, setPremiumPlusExpSortDir] = useState<'asc' | 'desc' | null>(null);
    const [registerDateSortDir, setRegisterDateSortDir] = useState<'asc' | 'desc' | null>(null);

    const maxStage = Math.max(...deliveries.map(delivery => parseInt(delivery.stage)));
    const stages = Array.from({ length: maxStage }, (_, i) => (i + 1).toString());
    const deliveryStatusMap: Record<DeliveryStatus, string> = { none: 'ยังไม่ส่ง', physical: 'ส่งหนังสือ', ebook: 'ส่ง E-Book', ignore: 'เพิกเฉย' };
    const emptyAddress = '—';
    const usersPerPage = 10;
    const totalPages = Math.ceil(churnedStudents.length / usersPerPage);
    const { token } = loginState.data;



    const fetchDeliveries = async () => {
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/deliveries`, token);
            setDeliveries(response.deliveries);
        } catch (err) {
            console.error('Error fetching deliveries:', err);
        }
    };

    const fetchCourses = async () => {
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/school-room`, token);
            setSchoolRooms(response.schoolRooms);
        } catch (error) {
            console.error('Error fetching school rooms:', error);
        }
    };

    const formatDate = (expireTime: moment.MomentInput) => expireTime ? moment(expireTime).format('D MMM, YY') : '—';

    const handleSortName = () => {
        setNameSortDir(nameSortDir === 'asc' ? 'desc' : 'asc');
        setPremiumPlusExpSortDir(null);
        setCurrentPage(1);
    };

    const handleSortPremiumExp = () => {
        setPremiumPlusExpSortDir(premiumPlusExpSortDir === 'asc' ? 'desc' : 'asc');
        setNameSortDir(null);
        setCurrentPage(1);
    };

    const sortByName = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const nameA = a.fullname?.toLowerCase() || '';
        const nameB = b.fullname?.toLowerCase() || '';
        return direction === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    };

    const sortByExpireTime = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const aDate = a.expire_date ? moment(a.expire_date) : moment(0);
        const bDate = b.expire_date ? moment(b.expire_date) : moment(0);
        return direction === 'asc'
            ? aDate.isBefore(bDate) ? -1 : 1
            : aDate.isBefore(bDate) ? 1 : -1;
    };

    const sortByRegisterDate = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const aDate = a.register_date ? moment(a.register_date) : moment(0);
        const bDate = b.register_date ? moment(b.register_date) : moment(0);
        return direction === 'asc'
            ? aDate.isBefore(bDate) ? -1 : 1
            : aDate.isBefore(bDate) ? 1 : -1;
    };

    const toggleName = (id: string) => {
        setExpandedNames(prev => {
            const newSet = new Set(prev);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);
            return newSet;
        });
    };

    const toggleNickname = (id: string) => {
        setExpandedNicknames(prev => {
            const newSet = new Set(prev);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);
            return newSet;
        });
    };

    const toggleAddress = (id: string) => {
        setExpandedAddresses(prev => {
            const newSet = new Set(prev);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);
            return newSet;
        });
    };

    const renderPaginationButton = (pageNumber: number) => (
        <button
            key={pageNumber} onClick={() => setCurrentPage(pageNumber)}
            className={`user-pagination-button ${pageNumber === currentPage ? 'active' : ''}`}
        >
            {pageNumber}
        </button>
    );

    const handleExamResultClick = (userId: string, note: string) => {
        setEditingExamResult(userId);
        setExamResultInputValues(prev => ({ ...prev, [userId]: note }));
    };

    const handleExamResultChange = (userId: string, value: string) => {
        setExamResultInputValues(prev => ({ ...prev, [userId]: value }));
    };

    const handleExamResultBlur = async (userId: string) => {
        try {
            const newResult = examResultInputValues[userId];
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/user/update-result`, token, { userId, newResult });
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error updating Note:', error);
        } finally {
            setEditingExamResult(null);
        }
    };



    useEffect(() => {
        dispatch(fetchApprovedListRequest());
        fetchDeliveries();
        fetchCourses();
    }, []);



    const currentUsers = churnedStudents
        .slice()
        .sort((a, b) => {
            if (nameSortDir) return sortByName(a, b, nameSortDir);
            return 0;
        })
        .sort((a, b) => {
            if (premiumPlusExpSortDir) return sortByExpireTime(a, b, premiumPlusExpSortDir);
            return 0;
        })
        .sort((a, b) => {
            if (registerDateSortDir) return sortByRegisterDate(a, b, registerDateSortDir);
            return 0;
        })
        .slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage);



    return (
        <>
            <table className="table-container">
                <thead>
                    <tr>
                        <th className="user-table-center-nowrap">ID</th>
                        <th className="user-table-name" onClick={() => { handleSortName() }}>
                            Name {nameSortDir !== null ? (nameSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                        <th className="user-table-center-nowrap">Nickname</th>
                        <th className="user-table-center-nowrap">LINE Name</th>
                        <th className="user-table-center-nowrap">Grade</th>
                        <th className="user-table-center-nowrap">Course</th>
                        <th className="user-table-center-nowrap">Premium Exp.</th>
                        <th className="user-table-center-nowrap">Premium<br />Auto Renew</th>
                        <th className="user-table-center-nowrap" onClick={() => { handleSortPremiumExp() }}>
                            Premium Plus Exp. {premiumPlusExpSortDir !== null ? (premiumPlusExpSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                        <th className="user-table-center-nowrap" onClick={() => { setRegisterDateSortDir(registerDateSortDir === 'asc' ? 'desc' : 'asc'); }}>
                            Register Date {registerDateSortDir !== null ? (registerDateSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                        <th className="user-table-center-nowrap">Notes</th>
                        <th className="user-table-center-nowrap">Exam Results</th>
                        {stages.map((stage) => (
                            <th key={stage} className={`user-table-center-nowrap`}>Stage {stage}</th>
                        ))}
                        <th className="user-table-address">Address</th>
                        <th className="user-table-address">Phone</th>
                    </tr>
                </thead>
                <tbody>
                    {currentUsers.length ? (
                        currentUsers.map((user) => {
                            const nickname = user.login && user.login !== user.fullname ? user.login : "—";
                            const isNameExpanded = expandedNames.has(user.id);
                            const isNicknameExpanded = expandedNicknames.has(user.id);
                            const isExpanded = expandedAddresses.has(user.id);
                            const truncatedName = user.fullname.length > 20 ? `${user.fullname.substring(0, 20)}...` : user.fullname;
                            const truncatedNickname = nickname.length > 20 ? `${nickname.substring(0, 20)}...` : nickname;
                            const truncatedAddress = user.address ? user.address.length > 20 ? `${user.address.substring(0, 20)}...` : user.address : emptyAddress;
                            const formattedExpirationDate = user.expire_date ? moment(user.expire_date).format('DD MMM, YY') : emptyAddress;
                            const isAutoRenewActive = user.renew_date != null && user.renew_date !== '';

                            const userDeliveries = deliveries.filter(delivery => delivery.user_id === user.id);
                            const userStageStatuses: Record<string, string> = {};

                            userDeliveries.forEach(delivery => {
                                userStageStatuses[delivery.stage] = delivery.status || 'none';
                            });

                            return (
                                <tr key={user.id}>
                                    <td className="user-table-center-nowrap">
                                        <img className="user-table-profile-image" src={user.lowPhotoUrl || userImage} alt="" />
                                        {user.id}
                                    </td>
                                    <td className="user-table-name">
                                        <span onClick={() => toggleName(user.id)} style={{ cursor: 'pointer' }}>
                                            {isNameExpanded ? user.fullname : truncatedName}
                                        </span>
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        <span onClick={() => toggleNickname(user.id)} style={{ cursor: 'pointer' }}>
                                            {isNicknameExpanded ? nickname : truncatedNickname}
                                        </span>
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        {user.lineName || '—'}
                                    </td>
                                    <td className="user-table-center-nowrap">{mapGrade(user.grade)}</td>
                                    <td className="user-table-center-nowrap">
                                        {schoolRooms.find(room => room.id === parseInt(user.room_id))?.name || 'No room selected'}
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        {formatDate(user.expire_time)}
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        <input className="auto-renew-check" type="checkbox" checked={isAutoRenewActive} disabled />
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        {formattedExpirationDate}
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        {user.register_date ? moment(user.register_date).format('DD MMM, YY') : emptyAddress}
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        {user.course || '—'}
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        {editingExamResult === user.id ? (
                                            <div className="user-course-container">
                                                <input
                                                    type="text" autoFocus maxLength={255}
                                                    value={examResultInputValues[user.id] !== undefined ? examResultInputValues[user.id] : (user.examResult || '')}
                                                    onChange={(e) => handleExamResultChange(user.id, e.target.value)}
                                                    onBlur={() => handleExamResultBlur(user.id)}
                                                    onKeyDown={(e) => { if (e.key === 'Enter') { handleExamResultBlur(user.id); } }}
                                                />
                                            </div>
                                        ) : (
                                            <div className="user-course-container" onClick={() => handleExamResultClick(user.id, user.examResult)}>
                                                {user.examResult || '—'}
                                                <img src={pencilIcon} alt="Edit" onClick={() => handleExamResultClick(user.id, user.examResult)} />
                                            </div>
                                        )}
                                    </td>
                                    {stages.map((stage) => (
                                        <td key={stage} className={`user-table-center-nowrap`}>
                                            <div>
                                                {deliveryStatusMap[userStageStatuses[stage] as DeliveryStatus] || 'None'}
                                            </div>
                                        </td>
                                    ))}
                                    <td className="user-table-address">
                                        <span
                                            onClick={() => user.address && user.address !== emptyAddress ? toggleAddress(user.id) : null}
                                            style={{ cursor: user.address && user.address !== emptyAddress ? 'pointer' : 'default' }}
                                        >
                                            {isExpanded ? user.address : truncatedAddress}
                                        </span>
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        {user.parentPhone || '—'}
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr><td colSpan={7} className="no-playlists">No users available</td></tr>
                    )}
                </tbody>
                {churnedStudents.length > usersPerPage && (
                    <div className="user-pagination" style={{ marginTop: '20px' }}>
                        <button
                            onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}
                            className="user-pagination-button"
                        >
                            Previous
                        </button>
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map(renderPaginationButton)}
                        <button
                            onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}
                            className="user-pagination-button"
                        >
                            Next
                        </button>
                    </div>
                )}
            </table>
        </>
    );
};

export default ChurnedTable;
