import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { mapGrade } from '../../utils/mapGrade';
import { subjectInstructorMap } from '../../utils/subjectInstructorMap';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest } from '../../store/approval/actions';
import { FirebaseContext } from '../../firebase/Firebase';
import Achievements from '../achievements/achievements';
import './table.css';
import './overview-table.css';
import moment from 'moment';
import PulseLoader from 'react-spinners/PulseLoader';

interface OverviewUserTableProps {
    selectedUserId: number | null; selectedWeek: number | null; livestreamData: LivestreamData[]; courseName: string
}
interface LivestreamData { livestreamId: number; instructor: string; }
interface QuizAnswer { question_id: number; correct_choice: string; user_choice: string; }
interface HomeworkSubmit { id: number; user_id: number; student_answer: number; question_id: number; live_id: number }



const OverviewTable: React.FC<OverviewUserTableProps> = ({ selectedUserId, selectedWeek, livestreamData, courseName }) => {

    const { database: firebase } = useContext(FirebaseContext);
    const dispatch = useDispatch();
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList);
    const loginState = useSelector((state: ApplicationState) => state.autherize);

    const [attendanceMap, setAttendanceMap] = useState<Record<number, Set<string>>>({});
    const [commentText, setCommentText] = useState('');
    const [homeworkSubmitDataMap, setHomeworkSubmitDataMap] = useState<Record<number, HomeworkSubmit[]>>({});
    const [loadingAttendance, setLoadingAttendance] = useState<Record<number, boolean>>({});
    const [quizDataMap, setQuizDataMap] = useState<Record<number, QuizAnswer[]>>({});

    const selectedUser = approvedList.data?.find(user => Number(user.id) === selectedUserId);
    const { token } = loginState.data;

    if (!firebase) throw new Error('Database is not available');



    const fetchAttendance = async (liveId: number) => {
        setLoadingAttendance(prev => ({ ...prev, [liveId]: true }));
        const ref = firebase.ref(`${process.env.REACT_APP_DEVELOP === 'true' ? '/debug' : ''}/${liveId}/`);
        try {
            const snapshot = await ref.orderByKey().once('value');
            const data = snapshot.val() || {};
            const firebaseAttendanceSet = new Set<string>();

            Object.entries(data).forEach(([, vals]) => {
                const value = vals as { Id: number; };
                const userId = value.Id.toString();
                firebaseAttendanceSet.add(userId);
            });

            const attendanceResponse = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/attendance/live-quiz/${liveId}`, token);
            const apiAttendanceSet = new Set<string>(attendanceResponse);
            const mergedAttendanceSet = new Set<string>([...firebaseAttendanceSet, ...apiAttendanceSet]);
            setAttendanceMap(prev => ({ ...prev, [liveId]: mergedAttendanceSet }));

        } catch (error) {
            console.error("Error fetching attendance list:", error);
        } finally {
            setLoadingAttendance(prev => ({ ...prev, [liveId]: false }));
        }
    };

    const fetchCorrectAnswers = async (liveId: number, userId: string) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/v1/school/live-quiz/correct-answers?liveId=${liveId}&userId=${userId}`;
            const data: QuizAnswer[] = await callApi('GET', url, token);
            setQuizDataMap(prev => ({ ...prev, [liveId]: data }));
        } catch (error) {
            console.error('Error fetching correct answers:', error);
        }
    };

    const fetchHomeworkSubmit = async (liveId: number, userId: number) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/v1/school/homework-submission?liveId=${liveId}&userId=${userId}`;
            const response = await callApi('GET', url, token);
            const data: HomeworkSubmit[] = response.data;
            setHomeworkSubmitDataMap(prev => ({ ...prev, [liveId]: data }));
        } catch (error) {
            console.error('Error fetching homework submit:', error);
        }
    };

    const fetchStartTime = async (liveId: number) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/v1/live-stream/start-time/${liveId}`;
            const response = await callApi('GET', url, token);
            return response.data;
        } catch (error) {
            console.error('Error fetching start time:', error);
            return null;
        }
    };

    const getIsHomeworkSubmitted = (homeworkSubmissions: HomeworkSubmit[]): boolean => {
        return homeworkSubmissions.length > 0;
    };

    const getIsPresent = (attendance: Set<string>, selectedUserId: number | null): 'Present' | 'Absent' => {
        return selectedUserId && attendance.has(String(selectedUserId)) ? 'Present' : 'Absent';
    };

    const getQuizScores = (answers: QuizAnswer[]): { totalQuiz: number; correctQuiz: number } => {
        const totalQuiz = answers.length;
        const correctQuiz = answers.filter(answer => answer.user_choice === answer.correct_choice).length;
        return { totalQuiz, correctQuiz };
    };

    const calculateWeekRange = async (livestreamData: any[]): Promise<string> => {

        const weekCounts: { [key: string]: number } = {};
        const thaiMonths = [
            'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
            'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
        ];

        const startTimes = await Promise.all(
            livestreamData.map(async (livestream) => {
                const liveId = livestream.livestreamId;
                return await fetchStartTime(liveId);
            })
        );

        startTimes.forEach(startTime => {
            if (startTime) {
                const date = moment(startTime);
                const weekStart = date.clone().startOf('isoWeek');
                const weekEnd = weekStart.clone().endOf('isoWeek');
                const weekKey = `${weekStart.date()} ${thaiMonths[weekStart.month()]} — ${weekEnd.date()} ${thaiMonths[weekEnd.month()]}`;
                weekCounts[weekKey] = (weekCounts[weekKey] || 0) + 1;
            }
        });

        return Object.entries(weekCounts).reduce((max, week) => {
            return week[1] > max[1] ? week : max;
        }, ['', 0])[0];
    };

    const calculateResults = async () => {
        return await Promise.all(livestreamData.map(async (livestream) => {
            const liveId = livestream.livestreamId;
            const homeworkSubmissions = homeworkSubmitDataMap[liveId] || [];
            const statusHW = getIsHomeworkSubmitted(homeworkSubmissions);

            const attendance = attendanceMap[livestream.livestreamId] || new Set();
            const presenceStatus = getIsPresent(attendance, selectedUserId);
            const statusLS = presenceStatus === 'Present';

            const answers = quizDataMap[liveId] || [];
            const { totalQuiz, correctQuiz } = getQuizScores(answers);
            const scoreLS = totalQuiz > 0 ? `${correctQuiz} / ${totalQuiz}` : '—';

            return {
                statusHW, statusLS, scoreLS,
                order: subjectInstructorMap[livestream.instructor]?.order || Number.MAX_VALUE,
            };
        }));
    };

    const fetchComment = async (userId: number) => {
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/comment/${userId}`, token);
            setCommentText(response.comment || '');
        } catch (error) {
            console.error('Error fetching comment:', error);
        }
    };

    const saveComment = async () => {
        if (!selectedUserId) return;
        try {
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/comment/update`, token, {
                userId: selectedUserId, comment: commentText
            });
            alert('Comment saved successfully!');
        } catch (error) {
            console.error('Error saving comment:', error);
            alert('Failed to save comment');
        }
    };

    const generatePDF = async () => {
        if (!selectedUser || !selectedUserId || !selectedWeek) return;

        try {
            const weekRange = await calculateWeekRange(livestreamData);
            const results = await calculateResults();
            results.sort((a, b) => a.order - b.order);

            const body = {
                courseName, results, commentText,
                weekNo: selectedWeek, weekRange,
                studentName: selectedUser.fullname,
            };

            const url = `${process.env.REACT_APP_API_URL}/v1/school/generateSchoolReport`;
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'token': token },
                body: JSON.stringify(body)
            });
            if (!response.ok) throw new Error('Network response was not ok');

            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');

        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    const renderTableRows = () => {

        const instructors = livestreamData.map(data => ({
            ...data,
            instructor: subjectInstructorMap[data.instructor]?.subject || data.instructor,
            order: subjectInstructorMap[data.instructor]?.order || Number.MAX_VALUE,
        }));
        instructors.sort((a, b) => a.order - b.order);

        return instructors.map(({ livestreamId, instructor }, instructorIndex) => {
            const attendance = attendanceMap[livestreamId] || new Set();
            const isAttendanceLoading = loadingAttendance[livestreamId];
            const homeworkSubmissions = homeworkSubmitDataMap[livestreamId] || [];
            const answers = quizDataMap[livestreamId] || [];

            const isHomeworkSubmitted = getIsHomeworkSubmitted(homeworkSubmissions);
            const isPresent = getIsPresent(attendance, selectedUserId);
            const { totalQuiz, correctQuiz } = getQuizScores(answers);

            return (
                <tr key={instructorIndex}>
                    <td className="livestream-table-centered">
                        {instructor ? instructor : '—'}
                    </td>
                    <td className="livestream-table-centered">
                        <strong style={{ color: isHomeworkSubmitted ? 'green' : 'red' }}>
                            {isHomeworkSubmitted ? 'Submitted' : 'Missing'}
                        </strong>
                    </td>
                    <td className={`livestream-table-centered ${instructor ? (isAttendanceLoading ? 'loading-attendance' : (isPresent === 'Present' ? 'attendance-present' : 'attendance-absent')) : ''}`}>
                        {instructor ? (isAttendanceLoading ? <PulseLoader color="#0b7aff" /> : isPresent) : '—'}
                    </td>
                    <td className="livestream-table-centered">
                        {totalQuiz > 0 ? `${correctQuiz} / ${totalQuiz}` : '—'}
                    </td>
                </tr>
            );
        });
    };



    useEffect(() => { dispatch(fetchApprovedListRequest()); }, [dispatch]);

    useEffect(() => {
        if (selectedUserId && livestreamData.length > 0) {
            livestreamData.forEach(({ livestreamId }) => {
                fetchAttendance(livestreamId);
                fetchCorrectAnswers(livestreamId, String(selectedUserId));
                fetchHomeworkSubmit(livestreamId, selectedUserId);
            });
            fetchComment(selectedUserId);
        }
    }, [selectedUserId, livestreamData]);



    return (
        <div className="overview-table-container">
            <div className="overview-table-header">
                <h3><strong>Week:</strong> {selectedWeek}</h3>
            </div>
            <div className="overview-table-details-container">
                {selectedUser && (
                    <div className="overview-table-details-user">
                        <p><strong>Name:</strong> {selectedUser.fullname}</p>
                        <p><strong>Nickname:</strong> {selectedUser.login}</p>
                        <p><strong>Grade:</strong> {mapGrade(selectedUser.grade)}</p>
                    </div>
                )}

                <div className="overview-table-details-subjects">
                    <table className="table-container overview-table">
                        <thead>
                            <tr>
                                <th className="livestream-table-centered">Subject</th>
                                <th className="livestream-table-centered">Homework</th>
                                <th className="livestream-table-centered">Attendance</th>
                                <th className="livestream-table-centered">Quiz Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableRows()}
                        </tbody>
                    </table>
                    <textarea
                        placeholder="Write your comment here..."
                        value={commentText}
                        onChange={(e) => setCommentText(e.target.value)}
                    />
                    <div className="overview-buttons-container">
                        <button onClick={saveComment} className="save-comment-button">
                            Save Comment
                        </button>
                        <button onClick={generatePDF} className="generate-pdf-button">
                            Generate PDF
                        </button>
                    </div>
                </div>

                <Achievements livestreamData={livestreamData} attendanceMap={attendanceMap} selectedUserId={selectedUserId} />
            </div>
        </div>
    );
};

export default OverviewTable;