import React from 'react';
import { useSelector } from 'react-redux';
import UserTable from '../../components/table/user-table';
import PlaylistTable from '../../components/table/playlist-table';
import { ApplicationState } from '../../store';
import './index.css';
import logoGrey from '../../images/logo/logo-text-nock-grey.png';
import logoGrey2x from '../../images/logo/logo-text-nock-grey@2x.png';
import logoGrey3x from '../../images/logo/logo-text-nock-grey@3x.png';



const Management = () => {

    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList?.data || []);
    const approvedCount = approvedList.length;

    return (
        <div className='content'>
            <div className='w-100 mb-5'>
                <h2 className='dashboard-header'>
                    Current Students
                    <div className="dashboard-count">({approvedCount})</div>
                </h2>
                <UserTable />
            </div>

            <div className='w-100 mb-5'>
                <h2 className='dashboard-header'>Current Playlists</h2>
                <PlaylistTable />
            </div>

            <div className='dashboard-logo-image-container'>
                <img src={logoGrey} srcSet={`${logoGrey2x} 2x, ${logoGrey3x} 3x`} alt='' />
            </div>
        </div>
    );
};

export default Management;
