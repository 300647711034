import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { mapGrade } from '../../utils/mapGrade';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest } from '../../store/approval/actions';
import StudentFilter from '../../containers/filter/student-filter';
import moment from 'moment';
import './table.css';
import './overview-user-table.css';
import userImage from '../../images/user/user-image.png';
import { StudentInfo } from '../../store/approval/types';

interface OverviewUserTableProps {
    weekNo: number | null;
    selectedUserId: number | null;
    setSelectedUserId: (id: number | null) => void;
    roomId: string | null;
}

interface UserReportCheck {
    user_id: string;
    report_check: number;
    [key: string]: number | string | undefined;
}



const OverviewUserTable: React.FC<OverviewUserTableProps> = ({ weekNo, selectedUserId, setSelectedUserId, roomId }) => {

    const dispatch = useDispatch();
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList);
    const { token } = useSelector((state: ApplicationState) => state.autherize.data);
    const [searchQuery, setSearchQuery] = useState('');
    const [nameSortDir, setNameSortDir] = useState<'asc' | 'desc' | null>('asc');
    const [registerDateSortDir, setRegisterDateSortDir] = useState<'asc' | 'desc' | null>(null);
    const [expireDateSortDir, setExpireDateSortDir] = useState<'asc' | 'desc' | null>(null);
    const [userReportChecks, setUserReportChecks] = useState<UserReportCheck[]>([]);
    const [userSubjectChecks, setUserSubjectChecks] = useState<UserReportCheck[]>([]);



    const handleRowClick = (e: React.MouseEvent, userId: number) => {
        if ((e.target as HTMLElement).closest('#overview-checkbox-cell') || userId === selectedUserId) return;
        setSelectedUserId(userId);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const filterUsers = (user: StudentInfo, searchQuery: string, roomId: string | null) => {
        const lowerCaseSearchTerm = searchQuery.toLowerCase();
        const fieldsToSearch = [user.id?.toString(), user.fullname, user.login];
        return fieldsToSearch.some(field => field?.toLowerCase().includes(lowerCaseSearchTerm)) && user.room_id === roomId;
    };

    const sortByName = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const nameA = a.fullname?.toLowerCase() || '';
        const nameB = b.fullname?.toLowerCase() || '';
        return direction === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    };

    const sortByCreateTime = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const aDate = a.create_time ? moment(a.create_time) : moment(0);
        const bDate = b.create_time ? moment(b.create_time) : moment(0);
        return direction === 'asc'
            ? aDate.isBefore(bDate) ? -1 : 1
            : aDate.isBefore(bDate) ? 1 : -1;
    };

    const sortByExpireDate = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const aDate = a.expire_date ? moment(a.expire_date) : moment(0);
        const bDate = b.expire_date ? moment(b.expire_date) : moment(0);
        return direction === 'asc'
            ? aDate.isBefore(bDate) ? -1 : 1
            : aDate.isBefore(bDate) ? 1 : -1;
    };

    const handleSortName = () => {
        setNameSortDir(nameSortDir === 'asc' ? 'desc' : 'asc');
        setRegisterDateSortDir(null);
        setExpireDateSortDir(null);
    };

    const handleSortRegisterDate = () => {
        setRegisterDateSortDir(registerDateSortDir === 'asc' ? 'desc' : 'asc');
        setNameSortDir(null);
        setExpireDateSortDir(null);
    };

    const handleSortExpireDate = () => {
        setExpireDateSortDir(expireDateSortDir === 'asc' ? 'desc' : 'asc');
        setNameSortDir(null);
        setRegisterDateSortDir(null);
    };

    const formatDate = (date: moment.MomentInput) => date ? moment(date).format('D MMM, YY') : '—';

    const handleReportCheckboxChange = async (e: React.MouseEvent, userId: string, currentReportCheck: number) => {
        e.stopPropagation();
        try {
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/user/report/toggle-check`, token, { userId, currentReportCheck, roomId, weekNo });
            await fetchUserReportChecks();
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error toggling Report Check:', error);
        }
    };

    const handleSubjectCheckboxChange = async (e: React.MouseEvent, userId: string, currentSubjectCheck: number, subject: number) => {
        e.stopPropagation();
        try {
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/user/report/toggle-check/subject`, token, { userId, currentSubjectCheck, roomId, weekNo, subject });

            const updatedSubjectChecks: UserReportCheck[] = await fetchSubjectChecks(subject);
            setUserSubjectChecks(prevState => {
                const updatedChecks = prevState.map((check) => {
                    if (check.user_id === userId) {
                        return {
                            ...check,
                            [`subject_check_${subject}`]: updatedSubjectChecks.find((updatedCheck) => updatedCheck.user_id === userId)?.[`subject_check_${subject}`]
                        };
                    }
                    return check;
                });

                return updatedChecks;
            });

            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error(`Error toggling Subject ${subject} Check:`, error);
        }
    };

    const fetchUserReportChecks = async () => {
        if (!roomId || !weekNo) return;
        try {
            const url = `${process.env.REACT_APP_API_URL}/v1/school/user/report/check?roomId=${roomId}&weekNo=${weekNo}`;
            const response = await callApi('GET', url, token);
            if (!response) throw new Error('Failed to fetch user report checks');
            setUserReportChecks(response.data);
        } catch (error) {
            console.error('Error fetching user report checks:', error);
        }
    };

    const fetchSubjectChecks = async (subject: number) => {
        if (!roomId || !weekNo) return;
        try {
            const url = `${process.env.REACT_APP_API_URL}/v1/school/user/report/check/subject?roomId=${roomId}&weekNo=${weekNo}&subject=${subject}`;
            const response = await callApi('GET', url, token);
            if (!response) throw new Error(`Failed to fetch subject ${subject} checks`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching subject ${subject} checks:`, error);
            return [];
        }
    };

    const fetchAllSubjectChecks = async (): Promise<void> => {
        const subject1Checks: UserReportCheck[] = await fetchSubjectChecks(1);
        const subject2Checks: UserReportCheck[] = await fetchSubjectChecks(2);
        const subject3Checks: UserReportCheck[] = await fetchSubjectChecks(3);
        const subject4Checks: UserReportCheck[] = await fetchSubjectChecks(4);
        const combinedChecks = subject1Checks.map((check: UserReportCheck, index: number) => ({
            ...check,
            subject_check_1: subject1Checks[index]?.subject_check_1,
            subject_check_2: subject2Checks[index]?.subject_check_2,
            subject_check_3: subject3Checks[index]?.subject_check_3,
            subject_check_4: subject4Checks[index]?.subject_check_4,
        }));
        setUserSubjectChecks(combinedChecks);
    };

    const getDateClass = (date: moment.MomentInput) =>
        date && moment(date).isBefore(moment()) ? 'expire-red' :
            date && moment(date).isBefore(moment().add(2, 'weeks')) ? 'expire-yellow' :
                '';



    useEffect(() => { dispatch(fetchApprovedListRequest()); }, [dispatch]);

    useEffect(() => {
        if (!roomId || !weekNo) return;
        fetchUserReportChecks();
        fetchAllSubjectChecks();
    }, [roomId, weekNo]);



    const sortedList = approvedList.data ? approvedList.data
        .filter(user => filterUsers(user, searchQuery, roomId))
        .sort((a, b) => {
            if (nameSortDir) return sortByName(a, b, nameSortDir);
            return 0;
        })
        .sort((a, b) => {
            if (registerDateSortDir) return sortByCreateTime(a, b, registerDateSortDir);
            return 0;
        })
        .sort((a, b) => {
            if (expireDateSortDir) return sortByExpireDate(a, b, expireDateSortDir);
            return 0;
        }) : [];

    const reportCounts = useMemo(() => {
        const counts: { [key: string]: number } = {
            reportedCount: 0,
            notReportedCount: 0,
            subject1ReportedCount: 0,
            subject1NotReportedCount: 0,
            subject2ReportedCount: 0,
            subject2NotReportedCount: 0,
            subject3ReportedCount: 0,
            subject3NotReportedCount: 0,
            subject4ReportedCount: 0,
            subject4NotReportedCount: 0,
        };

        sortedList.forEach(({ id }) => {
            const isReported = userReportChecks.some(report => report.user_id === id && report.report_check === 1);
            counts[isReported ? 'reportedCount' : 'notReportedCount']++;

            userSubjectChecks.forEach((subjectCheck) => {
                if (subjectCheck.user_id === id) {
                    for (let subject = 1; subject <= 4; subject++) {
                        const subjectKey = `subject_check_${subject}`;
                        const isSubjectChecked = subjectCheck[subjectKey] === 1;

                        if (isSubjectChecked) {
                            counts[`subject${subject}ReportedCount`]++;
                        } else {
                            counts[`subject${subject}NotReportedCount`]++;
                        }
                    }
                }
            });
        });

        return counts;
    }, [sortedList, userReportChecks, userSubjectChecks]);



    return (
        <div className="overview-user-table-container">
            <div className="report-check-container">
                <div className="report-check-controls-container">
                    <input
                        type="text" placeholder="Search by id or name" className="livestream-table-search-box"
                        value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <StudentFilter />
                </div>
                <div className="report-check-card-container">
                    <div className="report-card">
                        <p>คณิตพื้นฐาน</p>
                        <p className="report-number" style={{ color: '#0073ff' }}>
                            {reportCounts.subject1ReportedCount}
                        </p>
                    </div>
                    <div className="report-card">
                        <p>คณิตพื้นฐาน</p>
                        <p className="report-number" style={{ color: '#ff7300' }}>
                            {reportCounts.subject1NotReportedCount}
                        </p>
                    </div>
                </div>
                <div className="report-check-card-container">
                    <div className="report-card">
                        <p>คณิตเสริม</p>
                        <p className="report-number" style={{ color: '#0073ff' }}>
                            {reportCounts.subject2ReportedCount}
                        </p>
                    </div>
                    <div className="report-card">
                        <p>คณิตเสริม</p>
                        <p className="report-number" style={{ color: '#ff7300' }}>
                            {reportCounts.subject2NotReportedCount}
                        </p>
                    </div>
                </div>
                <div className="report-check-card-container">
                    <div className="report-card">
                        <p>วิทย์</p>
                        <p className="report-number" style={{ color: '#0073ff' }}>
                            {reportCounts.subject3ReportedCount}
                        </p>
                    </div>
                    <div className="report-card">
                        <p>วิทย์</p>
                        <p className="report-number" style={{ color: '#ff7300' }}>
                            {reportCounts.subject3NotReportedCount}
                        </p>
                    </div>
                </div>
                <div className="report-check-card-container">
                    <div className="report-card">
                        <p>อังกฤษ</p>
                        <p className="report-number" style={{ color: '#0073ff' }}>
                            {reportCounts.subject4ReportedCount}
                        </p>
                    </div>
                    <div className="report-card">
                        <p>อังกฤษ</p>
                        <p className="report-number" style={{ color: '#ff7300' }}>
                            {reportCounts.subject4NotReportedCount}
                        </p>
                    </div>
                </div>
                <div className="report-check-card-container">
                    <div className="report-card">
                        <p>Sent</p>
                        <p className="report-number" style={{ color: '#0073ff' }}>{reportCounts.reportedCount}</p>
                    </div>
                    <div className="report-card">
                        <p>Not Sent</p>
                        <p className="report-number" style={{ color: '#ff7300' }}>{reportCounts.notReportedCount}</p>
                    </div>
                </div>
            </div>

            <table className="table-container overview-user-table">
                <thead>
                    <tr>
                        <th className="user-table-center-nowrap">ID</th>
                        <th className="livestream-table-name" onClick={handleSortName}>
                            Name {nameSortDir !== null ? (nameSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                        <th className="livestream-table-centered">Nickname</th>
                        <th className="livestream-table-centered">Grade</th>
                        <th className="user-table-center-nowrap" onClick={handleSortRegisterDate}>
                            Register Date {registerDateSortDir !== null ? (registerDateSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                        <th className="user-table-center-nowrap" onClick={handleSortExpireDate}>
                            Expire Date {expireDateSortDir !== null ? (expireDateSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                        <th className="livestream-table-centered">คณิตพื้นฐาน</th>
                        <th className="livestream-table-centered">คณิตเพิ่มเติม</th>
                        <th className="livestream-table-centered">วิทยาศาสตร์</th>
                        <th className="livestream-table-centered">ภาษาอังกฤษ</th>
                        <th className="livestream-table-centered">Send Status</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedList.length > 0 ? (
                        sortedList.map((user) => {
                            const nickname = user.login && user.login !== user.fullname ? user.login : "-";
                            const userId = Number(user.id);
                            const isSelected = selectedUserId === userId;
                            const currentReportCheck = userReportChecks.find(report => report.user_id === user.id)?.report_check || 0;
                            const subjectChecks = userSubjectChecks.find(report => report.user_id === user.id);
                            return (
                                <tr
                                    key={user.id} onClick={(e) => handleRowClick(e, userId)}
                                    className={`overview-user-table-row ${isSelected ? 'selected' : ''}`}
                                >
                                    <td className="user-table-center-nowrap">
                                        <img className="user-table-profile-image" src={user.lowPhotoUrl || userImage} alt="" />
                                        {user.id}
                                    </td>
                                    <td>{user.fullname}</td>
                                    <td className="livestream-table-centered">{nickname}</td>
                                    <td className="livestream-table-centered">{mapGrade(user.grade)}</td>
                                    <td className="livestream-table-centered">{formatDate(user.register_date)}</td>
                                    <td className={`livestream-table-centered  ${getDateClass(user.expire_date)}`}>{formatDate(user.expire_date)}</td>
                                    <td
                                        className="livestream-table-centered"
                                        onClick={(e) => { handleSubjectCheckboxChange(e, user.id, Number(subjectChecks?.subject_check_1) || 0, 1); }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={subjectChecks?.subject_check_1 === 1}
                                            onClick={(e) => { handleSubjectCheckboxChange(e, user.id, Number(subjectChecks?.subject_check_1) || 0, 1); }}
                                        />
                                    </td>
                                    <td
                                        className="livestream-table-centered"
                                        onClick={(e) => { handleSubjectCheckboxChange(e, user.id, Number(subjectChecks?.subject_check_2) || 0, 2); }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={subjectChecks?.subject_check_2 === 1}
                                            onClick={(e) => { handleSubjectCheckboxChange(e, user.id, Number(subjectChecks?.subject_check_2) || 0, 2); }}
                                        />
                                    </td>
                                    <td
                                        className="livestream-table-centered"
                                        onClick={(e) => { handleSubjectCheckboxChange(e, user.id, Number(subjectChecks?.subject_check_3) || 0, 3); }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={subjectChecks?.subject_check_3 === 1}
                                            onClick={(e) => { handleSubjectCheckboxChange(e, user.id, Number(subjectChecks?.subject_check_3) || 0, 3); }}
                                        />
                                    </td>
                                    <td
                                        className="livestream-table-centered"
                                        onClick={(e) => { handleSubjectCheckboxChange(e, user.id, Number(subjectChecks?.subject_check_4) || 0, 4); }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={subjectChecks?.subject_check_4 === 1}
                                            onClick={(e) => { handleSubjectCheckboxChange(e, user.id, Number(subjectChecks?.subject_check_4) || 0, 4); }}
                                        />
                                    </td>
                                    <td
                                        className="livestream-table-centered" id="overview-checkbox-cell"
                                        onClick={(e) => { handleReportCheckboxChange(e, user.id, currentReportCheck); }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={userReportChecks.some(report => report.user_id === user.id && report.report_check === 1)}
                                            onClick={(e) => { handleReportCheckboxChange(e, user.id, currentReportCheck); }}
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={10} className="no-playlists">No User Available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default OverviewUserTable;