export type ToggleControls = 'top' | 'dashboard' | 'general' | string;

export interface Playlist {
    id: string;
    name: string;
    roomId: string;
    instructor: string;
}

export enum ToggleActionTypes {
    SET_TOGGLE = '@@toggle/SET_TOGGLE',
    SET_PLAYLISTS = '@@toggle/SET_PLAYLISTS'
}

export interface ToggleState {
    toggle: ToggleControls
}

export interface ToggleState {
    toggle: ToggleControls;
    playlists: Playlist[];
}