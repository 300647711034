import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import { setToggle } from '../../store/toggle/actions';
import Header from '../../components/header/header';
import LoginPage from '../login';
import ManagementPage from '../management';
import DashboardPage from '../dashboard';
import OverviewPage from '../overview';
import PlaylistPage from '../playlist';
import ReportPage from '../reports';
import './index.css';

interface SelectedRoom { roomId: string | null; roomName: string | null; }



const Main = () => {

    const dispatch = useDispatch();
    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const toggleState = useSelector((state: ApplicationState) => state.toggle);
    const playlists = useSelector((state: ApplicationState) => state.toggle.playlists);

    const [schoolRooms, setSchoolRooms] = useState<any[]>([]);
    const [selectedRoom, setSelectedRoom] = useState<SelectedRoom>({ roomId: null, roomName: null });

    const { token } = loginState.data || {};

    const filteredPlaylists = selectedRoom.roomId
        ? playlists.filter(playlist => playlist.roomId === selectedRoom.roomId)
        : [];



    const extractPlaylistName = (name: string) => {
        if (!name) return;
        const match = name.match(/(?:.*\s)([^ \d][^]*)/);
        return match ? match[1] : name;
    };

    const fetchCourses = async () => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/v1/school/school-room`;
            const response = await callApi('GET', url, token);
            if (response) setSchoolRooms(response.schoolRooms);
        } catch (error) {
            console.error('Error fetching school rooms:', error);
        }
    };

    const handleSchoolRoomClick = (schoolRoomId: string) => {
        const selectedRoom = schoolRooms.find(room => room.id === schoolRoomId);
        if (selectedRoom) setSelectedRoom({ roomId: schoolRoomId, roomName: selectedRoom.name });
        dispatch(setToggle(`schoolRoom-${schoolRoomId}`));
    };

    const handleNonSchoolRoomClick = (toggle: string) => {
        setSelectedRoom({ roomId: null, roomName: null });
        dispatch(setToggle(toggle));
    };



    useEffect(() => { fetchCourses(); }, []);



    if (!loginState.data) return <LoginPage />;

    return (
        <>
            <Header />

            <div className="main-toggle-container">
                <button
                    onClick={() => handleNonSchoolRoomClick('top')}
                    className={`main-toggle-button mr-2 ${toggleState.toggle === 'top' ? 'active' : ''}`}
                >
                    Management
                </button>

                {schoolRooms.map((schoolRoom) => (
                    <button
                        key={schoolRoom.id} onClick={() => handleSchoolRoomClick(schoolRoom.id)}
                        className={`main-toggle-button mr-2 ${toggleState.toggle === `schoolRoom-${schoolRoom.id}` || selectedRoom.roomId === schoolRoom.id ? 'active' : ''}`}
                    >
                        {schoolRoom.name}
                    </button>
                ))}

                <button
                    onClick={() => handleNonSchoolRoomClick('report')}
                    className={`main-toggle-button mr-2 ${toggleState.toggle === 'report' ? 'active' : ''}`}
                >
                    Reports
                </button>
            </div>

            <div className="main-toggle-container">
                {selectedRoom.roomId && (
                    <button
                        onClick={() => dispatch(setToggle('dashboard'))}
                        className={`main-toggle-button mr-2 ${toggleState.toggle === 'dashboard' ? 'active' : ''}`}
                    >
                        Dashboard
                    </button>
                )}
                {selectedRoom.roomId && (
                    <button
                        onClick={() => dispatch(setToggle('general'))}
                        className={`main-toggle-button mr-2 ${toggleState.toggle === 'general' ? 'active' : ''}`}
                    >
                        Overview
                    </button>
                )}
                {selectedRoom.roomId && filteredPlaylists.map((playlist) => (
                    <button
                        key={playlist.id} onClick={() => dispatch(setToggle(`playlist-${playlist.id}`))}
                        className={`main-toggle-button ${toggleState.toggle === `playlist-${playlist.id}` ? 'active' : ''}`}
                    >
                        {extractPlaylistName(playlist.name)} – {playlist.instructor}
                    </button>
                ))}
            </div>

            {toggleState.toggle === 'top' && <ManagementPage />}
            {toggleState.toggle === 'dashboard' && selectedRoom.roomId && (
                <DashboardPage roomId={selectedRoom.roomId} />
            )}
            {toggleState.toggle === 'general' && selectedRoom.roomId && (
                <OverviewPage roomId={selectedRoom.roomId} roomName={selectedRoom.roomName} />
            )}
            {filteredPlaylists.map((playlist) => (
                toggleState.toggle === `playlist-${playlist.id}` && <div key={playlist.id}><PlaylistPage roomId={selectedRoom.roomId} /></div>
            ))}
            {toggleState.toggle === 'report' && <ReportPage />}
        </>
    );
};

export default Main;
