import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import PulseLoader from 'react-spinners/PulseLoader';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ReportTimePeriodControls from './ReportTimePeriodControls';
import { generateDateRanges, ReportTooltip, ReportTooltipPercentage } from './ReportsUtils';

type ChurnChartData = { name: string; churn: number; renewal: number; exUsers: number; };
type CountChartData = { name: string; newCustomers: number; };
type DateRange = { startDate: string; endDate: string; };
type Period = 'weekly' | 'monthly' | 'yearly';



const CustomerReport = () => {

    const { token } = useSelector((state: ApplicationState) => state.autherize.data);

    const [churnData, setChurnData] = useState<ChurnChartData[]>([]);
    const [countData, setCountData] = useState<CountChartData[]>([]);

    const [churnDateRanges, setChurnDateRanges] = useState<DateRange[]>([]);
    const [countDateRanges, setCountDateRanges] = useState<DateRange[]>([]);

    const [churnTimePeriod, setChurnTimePeriod] = useState<Period>('monthly');
    const [countTimePeriod, setCountTimePeriod] = useState<Period>('monthly');

    const [churnCustomStartDate, setChurnCustomStartDate] = useState<string>('');
    const [churnCustomEndDate, setChurnCustomEndDate] = useState<string>('');

    const [countCustomStartDate, setCountCustomStartDate] = useState<string>('');
    const [countCustomEndDate, setCountCustomEndDate] = useState<string>('');

    const [loadingChurnData, setLoadingChurnData] = useState<boolean>(false);
    const [loadingCountData, setLoadingCountData] = useState<boolean>(false);



    const fetchDataForRange = async (startDate: string, endDate: string, type: 'churn' | 'count') => {
        try {
            const params = new URLSearchParams({ startDate, endDate });
            const url = `${process.env.REACT_APP_API_URL}/v1/school/report/customer/${type}?${params.toString()}`;
            return await callApi('GET', url, token);
        } catch (error) {
            console.error(`Error fetching ${type} data for range:`, error);
            return null;
        }
    };

    const fetchChurnData = async () => {
        setLoadingChurnData(true);
        const allChurnData: ChurnChartData[] = [];
        for (const range of churnDateRanges) {
            const data = await fetchDataForRange(range.startDate, range.endDate, 'churn');
            if (data) { allChurnData.push({ name: range.endDate, churn: data.churn, renewal: data.renewal, exUsers: data.exUsers }); }
        }
        setChurnData(allChurnData);
        setLoadingChurnData(false);
    };

    const fetchCountData = async () => {
        setLoadingCountData(true);
        const allCountData: CountChartData[] = [];
        for (const range of countDateRanges) {
            const data = await fetchDataForRange(range.startDate, range.endDate, 'count');
            if (data) { allCountData.push({ name: range.endDate, newCustomers: data.customerCount }); }
        }
        setCountData(allCountData);
        setLoadingCountData(false);
    };



    useEffect(() => {
        const churnRanges = generateDateRanges(churnTimePeriod, churnCustomStartDate, churnCustomEndDate);
        setChurnDateRanges(churnRanges);
    }, [churnTimePeriod, churnCustomStartDate, churnCustomEndDate]);

    useEffect(() => {
        const countRanges = generateDateRanges(countTimePeriod, countCustomStartDate, countCustomEndDate);
        setCountDateRanges(countRanges);
    }, [countTimePeriod, countCustomStartDate, countCustomEndDate]);

    useEffect(() => { if (churnDateRanges.length > 0) fetchChurnData(); }, [churnDateRanges]);

    useEffect(() => { if (countDateRanges.length > 0) fetchCountData(); }, [countDateRanges]);



    return (
        <div className='content'>
            <div className='w-100 mb-5'>
                <h2 className='dashboard-header'>Churn Report</h2>
            </div>

            {churnData.length > 0 && (
                <div className='report-card-time-container'>
                    <div className='w-100 mb-5'>
                        <div className='report-card-container'>
                            <div className='report-card'>
                                <p>Renewal Rate</p>
                                <p className='report-number' style={{ color: '#0073ff' }}>
                                    {churnData[churnData.length - 1]?.renewal || 0} %
                                </p>
                            </div>
                            <div className='report-card'>
                                <p>Churn Rate</p>
                                <p className='report-number' style={{ color: '#ff7300' }}>
                                    {churnData[churnData.length - 1]?.churn || 0} %
                                </p>
                            </div>
                        </div>
                    </div>
                    <ReportTimePeriodControls
                        timePeriod={churnTimePeriod}
                        setTimePeriod={setChurnTimePeriod}
                        customStartDate={churnCustomStartDate}
                        setCustomStartDate={setChurnCustomStartDate}
                        customEndDate={churnCustomEndDate}
                        setCustomEndDate={setChurnCustomEndDate}
                    />
                </div>
            )}

            <div className='w-100 mb-5 report-chart-container' style={{ width: '100%', height: 300 }}>
                {loadingChurnData ? (
                    <PulseLoader color="#0b7aff" />
                ) : (
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={churnData} margin={{ bottom: 20 }} >
                            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                            <XAxis dataKey="name" dy={20} />
                            <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} dx={-10} />
                            <Tooltip content={<ReportTooltipPercentage />} />
                            <Legend
                                verticalAlign="top" wrapperStyle={{ padding: '10px', }}
                                formatter={(value) => <span style={{ fontWeight: 'bold', color: '#333' }}>{value}</span>}
                            />
                            <Line
                                type="monotone" dataKey="churn" name="Churn"
                                stroke="#ff7300" strokeWidth={3} dot={{ r: 4 }} activeDot={{ r: 8 }}
                            />
                            <Line
                                type="monotone" dataKey="exUsers" name="Students"
                                stroke="#0088FE" strokeWidth={0} activeDot={{ r: 0 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </div>

            <div className='w-100 mb-5'>
                <h2 className='dashboard-header'>Customers Report</h2>
            </div>

            {countData.length > 0 && (
                <div className='report-card-time-container'>
                    <div className='w-100 mb-5'>
                        <div className='report-card-container'>
                            <div className='report-card'>
                                <p>New Customers</p>
                                <p className='report-number' style={{ color: '#8884d8' }}>
                                    {countData[countData.length - 1]?.newCustomers || 0}
                                </p>
                            </div>
                        </div>
                    </div>
                    <ReportTimePeriodControls
                        timePeriod={countTimePeriod}
                        setTimePeriod={setCountTimePeriod}
                        customStartDate={countCustomStartDate}
                        setCustomStartDate={setCountCustomStartDate}
                        customEndDate={countCustomEndDate}
                        setCustomEndDate={setCountCustomEndDate}
                    />
                </div>
            )}

            <div className='w-100 mb-5 report-chart-container' style={{ width: '100%', height: 300 }}>
                {loadingCountData ? (
                    <PulseLoader color="#0b7aff" />
                ) : (
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={countData} margin={{ bottom: 20 }}>
                            <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                            <XAxis dataKey="name" dy={20} />
                            <YAxis dx={-10} />
                            <Tooltip content={<ReportTooltip />} />
                            <Legend
                                verticalAlign="top" wrapperStyle={{ padding: '10px', }}
                                formatter={(value) => <span style={{ fontWeight: 'bold', color: '#333' }}>{value}</span>}
                            />
                            <Line
                                type="monotone" dataKey="newCustomers" name="New Customers"
                                stroke="#8884d8" strokeWidth={3} dot={{ r: 4 }} activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </div>
        </div>
    );
};

export default CustomerReport;
