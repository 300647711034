import React from 'react';
import './achievements.css';

interface AchievementsProps {
    livestreamData: { livestreamId: number }[];
    attendanceMap: Record<number, Set<string>>;
    selectedUserId: number | null;
}


const Achievements: React.FC<AchievementsProps> = ({ livestreamData, attendanceMap, selectedUserId }) => {

    const isWeekAttendanceComplete = livestreamData.every(({ livestreamId }) => {
        const attendance = attendanceMap[livestreamId] || new Set();
        return attendance.has(String(selectedUserId));
    });

    return (
        <div className="achievements-container">
            <h4>Achievements</h4>
            <div className="achievements-card">
                <span>Complete Week Attendance {isWeekAttendanceComplete ? '✔️' : '❌'}</span>
            </div>
        </div>
    );
};

export default Achievements;
